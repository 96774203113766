@tailwind base;
@tailwind components;
@tailwind utilities;

/* Define the font */
@font-face {
  font-family: 'Orbitron', sans-serif;
  src: url('https://fonts.googleapis.com/css2?family=Orbitron:wght@700&display=swap');
}

/* Apply the Orbitron font to the entire website */
html {
  font-family: 'Orbitron', sans-serif;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  background: rgb(62,20,118);
  background: linear-gradient(90deg, rgb(18, 8, 32) 0%, rgb(2, 2, 39) 66%);}

.toggled #line {
  transform: translateX(-0.45rem) translateY(0.25rem) rotate(105deg);
}

.toggled #line2 {
  transform: translateY(-1rem) scaleX(80%) translateX(0.5em);
}

.toggled #line3 {
  transform: translateY(-0.7rem) translateX(0.15rem) scaleX(80%) rotate(0deg);
}

.btn {
  border: 1px solid #06aaeb;
  border-radius: 9999px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.btn:hover {
  background-color: #06aaeb;
}

.link {
  color: white;
}

.link:hover {
  color: #06aaeb;
}

.btn-connect-container {
  border: 1px solid #06aaeb;
  border-radius: 9999px;
}
